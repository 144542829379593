$colors: (
    /* Primary Color Palette */
    primary-color-lightest: #415DD3,
    primary-color-light: #1F40CB,
    primary-color: #1732BE,
    primary-color-dark: #0A1CAC,

    /* Accent Color Palette */
    accent-color: #0090E8,
    accent-color-dark: #0086E5,

    /* Success Color Palette */
    success-color: #2FC066,
    success-color-light: #DFFEE7,
    success-color-dark: #034216,

    /* Warning Color Palette */
    warning-color-light: #FFEDEB,
    warning-color: #FF5649,
    error-color: #dc3545,
    warning-color-dark: #DA1E28,

    warning-yellow: #F59E0B,

    color-beige: #F6F6F6,
    color-gray-50: #F2F2F4,
    color-gray-100: #DFE0E4,
    color-gray-150: #EDEDED,
    color-gray-200: #CACBD2,
    color-gray-300: #B4B6C0,
    color-gray-400: #A4A6B3,
    color-gray-ccc: #ccc,
    color-gray: #818393,
    color-gray-F0F0F5: #F0F0F5,
    color-black: #000000,
    color-black-control: #495057,
    color-white: #FFFFFF,
    color-gray-light: #E6E6EB,
    color-dark-gray: #666,
    color-gray-12: #868686,
    color-gray-13: #fafafa,
    color-gray-14: #d8d8d8,
    color-gray-15: #dcdcdc,

    color-yellow: #FFAF3F,
    color-yellow-dark: #F5BC00,
    color-orange: #EB6300,
    color-orange-dark: #EB6300,
    color-black-text: #0E0D0F,
    color-black-pearl: #051C2C,
    color-teal: #0AC5A1,
    color-pink: #F099BB,
    color-hot-pink: #D90056,
    color-purple: #2E308E,
    color-purple-light: #8A3FFC,

    /* Color palette */
    /* Red */
    color-red-10: #FFEFEF,
    color-red-20: #FED8D9,
    color-red-30: #FBB0B3,
    color-red-40: #FF7E84,
    color-red-50: #F84E57,
    color-red-60: #DA1E28,
    color-red-70: #A81921,
    color-red-80: #7A1016,
    color-red-90: #4F0408,
    color-red-100: #2B0709,

    /* Magenta */
    color-magenta-10: #FFEBF5,
    color-magenta-20: #FFD8EA,
    color-magenta-30: #FBADD0,
    color-magenta-40: #FF79B4,
    color-magenta-50: #ED5095,
    color-magenta-60: #D02670,
    color-magenta-70: #A11B55,
    color-magenta-80: #750A39,
    color-magenta-90: #500224,
    color-magenta-100: #280A17,

    /* Purple */
    color-purple-10: #F5F1FD,
    color-purple-20: #E6D8FD,
    color-purple-30: #D3B8FE,
    color-purple-40: #C097FD,
    color-purple-50: #A86EFE,
    color-purple-60: #8A3FFC,
    color-purple-70: #6729C4,
    color-purple-80: #4B1F8C,
    color-purple-90: #341561,
    color-purple-100: #1C0F30,

    /* Blue */
    color-light-blue: #F2F7FE,
    color-blue-20: #CFE3FD,
    color-blue-30: #A1C5FE,
    color-blue-40: #76A9FD,
    color-blue-50: #4889FC,
    color-blue-60: #0F62FE,
    color-blue-70: #0244D0,
    color-blue-80: #00309E,
    color-blue-90: #002070,
    color-blue-100: #001242,

    /* Cyan */
    color-cyan-10: #E3F3FC,
    color-cyan-20: #BBE4FD,
    color-cyan-30: #83CCFD,
    color-cyan-40: #33ADFF,
    color-cyan-50: #1291E6,
    color-info-blue: #0270C4,
    color-cyan-70: #005494,
    color-cyan-80: #013C6B,
    color-cyan-90: #00284A,
    color-cyan-100: #051626,

    /* Teal */
    color-teal-10: #D3F9F9,
    color-teal-20: #A0F0EE,
    color-teal-30: #38D8D8,
    color-teal-40: #0DBFB9,
    color-teal-50: #009E99,
    color-teal-60: #007D7A,
    color-teal-70: #025E5C,
    color-teal-80: #00403E,
    color-teal-90: #022A2B,
    color-teal-100: #07181A,

    /* Green */
    color-green-20: #A8F0BD,
    color-green-30: #6EDD90,
    color-green-40: #43BF6A,
    color-green-dark: #24A34D,
    color-green-60: #177E39,
    color-green-70: #0C5C25,
    color-green-90: #012E09,
    color-green-100: #071A08,

    /* Yellow */
    color-yellow-10: #FDF6D9,
    color-yellow-20: #FADC69,
    color-yellow-30: #F0C31F,
    color-yellow-40: #D4A408,
    color-yellow-50: #B58905,
    color-yellow-60: #8F6E00,
    color-yellow-70: #6B4E04,
    color-yellow-80: #4A3700,
    color-yellow-90: #332600,
    color-yellow-100: #1C1500,

    /* Orange */
    color-orange-light: #FFF2E8,
    color-orange-20: #FFD8BA,
    color-orange-30: #FFB985,
    color-orange-40: #FF872D,
    color-orange-50: #EB6300,
    color-orange-60: #BA4E00,
    color-orange-70: #8C3B00,
    color-orange-80: #602A00,
    color-orange-90: #411D00,
    color-orange-100: #241100,

    /* Gray */
    color-gray-1000: #6E6F7A,
    color-gray-75: #D8DAE3,
    color-gray-3: #E6E6EB,
    color-gray-0: #F0F0F5,
    color-gray-5: #F7F7FB,
    color-gray-8: #8D8D947A,

    color-cream-10: #FFFBEB,
    color-butterscotch-10: #FDE68A,
    color-light-cream: #e0e0e0,
    color-ligth-gray: #eee,
    color-charcoal: #333,
    color-medium-dark-gray: #555,
    color-light-charcol-gray: #e6e6e6,
    color-misty-blue-gray: #e6e6eb,
);

:root {
    @each $name, $color in $colors {
      --#{$name}: #{$color};
    }
  }

// Dynamically generate classes
@mixin generate-color-classes($property, $prefix) {
    @each $name, $color in $colors {
        .#{$prefix}-#{$name} {
            #{$property}: var(--#{$name}) !important;
        }
    }
}

@include generate-color-classes('background-color', 'bg');
@include generate-color-classes('color', 'color');
@include generate-color-classes('border-color', 'border-color');
