.idp {
  min-height: auto !important;
  // Table overrides
  .mat-header-cell {
    &.left-align {
      text-align: left;
    }

    &.right-align {
      text-align: right;
    }

    &.center-align {
      text-align: center;
    }
  }

  .mat-cell {
    &.left-align {
      text-align: left;
    }

    &.right-align {
      text-align: right;
    }

    &.center-align {
      text-align: center;
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: white;
  }

  .mat-menu-content:not(:empty) {
    padding: 0 !important;
  }

  .custom-slider {
    mat-slider .mat-slider-thumb {
      width: 4px !important;
      height: 16px !important;
      border-radius: 0 !important;
      background-color: #D8DAE3 !important;
      top: -8px;
      right: -4px;
      box-shadow: 0 0 0 2px #ffffff;
    }

    mat-slider .mat-slider-wrapper .mat-slider-track-fill {
      background-color: #051C2C !important;
    }

    .mat-slider-horizontal .mat-slider-track-wrapper {
      height: 3px;
    }

    .mat-slider-horizontal .mat-slider-wrapper {
      left: 0px;
      right: 0px;
    }

    .mat-slider-horizontal .mat-slider-track-fill {
      height: 3px;
    }
  }

  .mat-tab-label .mat-tab-label-content {
    gap: 4px;
  }

  .mat-accordion .mat-expansion-panel:last-of-type {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .mat-accordion .mat-expansion-panel:first-of-type {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label {
    opacity: 1 !important;
  }

}
